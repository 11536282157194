/* eslint-disable max-lines-per-function */
import moment from 'moment-timezone';
import momentFr from '../config/moment.fr';
import momentEn from '../config/moment.en';
import { shoesTypes, clothSlugToElbowPadsPricingIdMap } from './pricing';
import { isMultilingual } from '../../languages';

if (isMultilingual) {
  moment.updateLocale('en-gb', momentEn);
} else {
  moment.updateLocale('fr', momentFr);
}
moment.tz.setDefault('Europe/Paris');

/**
 * Génère une liste d'altérations à partir des besoins (problems) signalés sur un vêtement.
 *
 * @param {Object} treeInverse - L'arborescence inversée des locations.
 * @param {Object} pricingInverse - Les informations de prix pour chaque altération.
 * @returns {Array} - La liste des altérations avec leurs quantités mises à jour.
 */
export function getAlterationsFromProblems(treeInverse, pricingInverse, {
  clothSlug, sizeBag, problems, knitTearOption, pantHemStyle, fabric, process,
}) {
  if (!problems) return [];
  const alterations = [];

  if (clothSlug === 'bag' && problems.dirty) {
    if (sizeBag === 'littleBag') alterations.push(pricingInverse['13.bag-prevent-1']);
    if (sizeBag === 'middleBag') alterations.push(pricingInverse['13.bag-prevent-2']);
    if (sizeBag === 'bigBag') alterations.push(pricingInverse['13.bag-prevent-3']);
  }
  if (clothSlug === 'bag' && problems.discolored) {
    if (sizeBag === 'littleBag') alterations.push(pricingInverse['13.bag-recolor-11']);
    if (sizeBag === 'middleBag') alterations.push(pricingInverse['13.bag-recolor-12']);
    if (sizeBag === 'bigBag') alterations.push(pricingInverse['13.bag-recolor-13']);
  }

  if ((clothSlug === 'pull' || fabric === 'stitch') && problems.damaged && knitTearOption === 'elbowPads') {
    const pricingId = clothSlugToElbowPadsPricingIdMap[clothSlug];
    alterations.push(pricingInverse[pricingId]);
  }

  Object.values(problems)
    .forEach((locations, index) => {
      if (typeof locations[0] === 'string' && knitTearOption !== 'elbowPads') {
        locations.forEach((locationSlug) => {
          // Certaines alterations peuvent avoir la même id ce qui peut fausser l'ajout des quantités.
          // Pour éviter cela, on ajoute une clé unique (key) composée de l'ID et du slug de location (cette key ne sera plus utilisée ensuite).
          const location = treeInverse[`${clothSlug}_${locationSlug}`];

          if (location.id === `13.${clothSlug}-rep-tear-3`) {
            alterations.push({ ...pricingInverse[location.id], key: `${location.id}-${locationSlug}` });
            alterations[alterations.length - 1].quantity = 2;
          } else if ((['pull', 'little_acce'].includes(clothSlug) || ['stitch', 'textile_stitch'].includes(fabric)) && locationSlug === 'tornHoley') {
            if (knitTearOption === 'remaillage') alterations.push({ ...pricingInverse[`13.${clothSlug}-rep-tear-7`], key: `${location.id}-${locationSlug}` });
            if (knitTearOption === 'mending') alterations.push({ ...pricingInverse[`13.${clothSlug}-rep-tear-5`], key: `${location.id}-${locationSlug}` });
            if (knitTearOption === 'creativeOption') {
              alterations.push({ ...pricingInverse[`13.${clothSlug}-rep-tear-5`], key: `${location.id}-${locationSlug}` });
              alterations.push({ ...pricingInverse[`13.${clothSlug}-bro-12`], key: `${location.id}-${locationSlug}` });
            }
          } else if (clothSlug === 'pant' && fabric === 'jeans' && locationSlug === 'hem' && process === 'PACKAGE') {
            if (pantHemStyle === 'simpleHem') alterations.push({ ...pricingInverse['13.pant-ret-rac-3'], key: `${location.id}-${locationSlug}` });
            if (pantHemStyle === 'batherHem') alterations.push({ ...pricingInverse['13.pant-ret-rac-4'], key: `${location.id}-${locationSlug}` });
          } else {
            alterations.push({ ...pricingInverse[location.id], key: `${location.id}-${locationSlug}` });
          }

          if (problems[`${locationSlug}_multiple`]) {
            const selectedQuantity = problems[`${locationSlug}_multiple`][0].value;
            if (clothSlug === 'bag' && locationSlug === 'scratched' && sizeBag === 'littleBag' && selectedQuantity >= 2) {
              alterations[alterations.length - 1] = pricingInverse['13.bag-prevent-1'];
            } else if (clothSlug === 'bag' && locationSlug === 'scratched' && ['middleBag', 'bigBag'].includes(sizeBag) && selectedQuantity >= 4) {
              alterations[alterations.length - 1] = pricingInverse['13.bag-prevent-2'];
            } else {
              const lastAlterationIndex = alterations.findIndex((alter) => alter.key === `${location.id}-${locationSlug}`);
              if (lastAlterationIndex !== -1) {
                alterations[lastAlterationIndex].quantity = selectedQuantity;
              }
            }
          }
        });
      } else if (Object.keys(problems)[index] === 'adjustment_acce_multiple') {
        alterations.push(pricingInverse['13.acce-rep-div-3']);
        alterations[alterations.length - 1].quantity = problems.adjustment_acce_multiple[0].value;
      }
    });
  return alterations;
}

function findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, name) {
  let updatedClothSlug = clothSlug;
  if (clothSlug === 'mail') updatedClothSlug = 'ling';
  if (clothSlug === 'cost') updatedClothSlug = suitPiece;
  return Object.values(pricingInverse).find((item) => item.id.includes(`${updatedClothSlug}-bro`)
    && item.name.includes(name)).id;
}

function getAlterationsFromEmbroideriesPackage(pricingInverse, {
  clothSlug, embroideriesPackage, embroideryPackageFontSize = '', embroideryPackageDrawingSize = '',
}) {
  if (!embroideriesPackage) return [];
  const alterations = [];
  if (embroideriesPackage[0].isActive) {
    switch (embroideryPackageFontSize) {
      case '0.5':
        alterations.push(pricingInverse[`13.${clothSlug}-bro-15`]);
        break;

      case '2':
        alterations.push(pricingInverse[`13.${clothSlug}-bro-16`]);
        break;

      case '3':
      default:
        alterations.push(pricingInverse[`13.${clothSlug}-bro-17`]);
    }
  }
  if (embroideriesPackage[1].isActive) {
    switch (embroideryPackageDrawingSize) {
      case '0.5':
        alterations.push(pricingInverse[`13.${clothSlug}-bro-18`]);
        break;

      case '2':
        alterations.push(pricingInverse[`13.${clothSlug}-bro-19`]);
        break;

      case '3':
      default:
        alterations.push(pricingInverse[`13.${clothSlug}-bro-20`]);
    }
  }
  return alterations;
}

export function getAlterationsFromEmbroideries(pricingInverse, {
  clothSlug, embroideries,
  isDrawingEasy, isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize, suitPiece,
}) {
  if (!embroideries) return [];
  const alterations = [];
  if (embroideries[0].isActive) alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Initiales'));
  if (embroideries[1].isActive) {
    if (activeEmbroideryFontSize === 0) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Un mot (10 lettres - petite police)'));
    } else if (activeEmbroideryFontSize === 1) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Un mot (10 lettres - grosse police)'));
    } else {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Un mot (10 lettres - grosse police pleine)'));
    }
  }
  if (embroideries[2].isActive) {
    if (isDrawingFull) {
      if (isDrawingEasy) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, activeEmbroideryDrawingSize === 0
          ? 'Dessin plein forme simple < 5cm' : 'Dessin plein forme simple < 12cm'));
      // complex full drawing
      } else if (activeEmbroideryDrawingSize === 0) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin plein complexe < 5cm'));
      } else {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin plein complexe < 12cm'));
      }
    // hollow easy drawing
    } else if (isDrawingEasy) {
      if (activeEmbroideryDrawingSize === 0) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin creux forme simple < 5cm'));
      } else if (activeEmbroideryDrawingSize === 1) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin creux forme simple < 12cm'));
      } else if (activeEmbroideryDrawingSize === 2) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin creux forme simple < 25cm'));
      }
    // hollow complex drawing
    } else if (activeEmbroideryDrawingSize === 0) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin creux complexe < 5cm'));
    } else if (activeEmbroideryDrawingSize === 1) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin creux complexe < 12cm'));
    } else if (activeEmbroideryDrawingSize === 2) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, suitPiece, 'Dessin creux complexe < 25cm'));
    }
  }
  return alterations.map((alterationId) => ({
    ...pricingInverse[alterationId],
    quantity: 1,
  }));
}

export function getAlterationsFromCreations(pricingInverse, {
  creationSize, creationHeight, creationFirstOption, creationSecondOption, creationNumberOfPieces = 1, otherItems = [],
}) {
  const alterations = [];
  let newOtherItems = [...otherItems];
  const creationSizeAlteration = creationSize.id && pricingInverse[creationSize.id];
  if (creationSizeAlteration) alterations.push({ ...creationSizeAlteration, quantity: creationNumberOfPieces });
  const creationHeightAlteration = creationHeight.id && pricingInverse[creationHeight.id];
  if (creationHeightAlteration) alterations.push({ ...creationHeightAlteration, quantity: creationNumberOfPieces });
  const creationFirstOptionAlteration = creationFirstOption.id && pricingInverse[creationFirstOption.id];
  if (creationFirstOptionAlteration) alterations.push({ ...creationFirstOptionAlteration, quantity: creationNumberOfPieces });
  const creationSecondOptionAlteration = creationSecondOption.id && pricingInverse[creationSecondOption.id];
  if (creationSecondOptionAlteration) {
    alterations
      .push({
        ...creationSecondOptionAlteration,
        quantity: creationNumberOfPieces + (newOtherItems.includes(creationSecondOptionAlteration.id) ? 1 : 0),
      });
    if (newOtherItems.includes(creationSecondOptionAlteration.id)) {
      newOtherItems = newOtherItems
        .filter((otherItem) => otherItem !== creationSecondOptionAlteration.id);
    }
  }

  if (newOtherItems) {
    newOtherItems.forEach((otherItem) => {
      alterations.push(pricingInverse[otherItem]);
    });
  }

  return alterations;
}

const getUpcyclingItemsFromPricingInverse = (upcyclingPricingInverse, upcyclingItems) => {
  const newUpcyclingItems = [];
  upcyclingItems.forEach(({ id, quantity }) => {
    newUpcyclingItems.push(upcyclingPricingInverse[id]);
    if (quantity) newUpcyclingItems[newUpcyclingItems.length - 1].quantity = quantity.value;
  });
  return newUpcyclingItems;
};

const getFormattedUpcyclingItems = (upcyclingItems, clothType) => (
  upcyclingItems.map(({
    name, id, quantity, prices, liningPrices, hasPriceRange,
  }) => {
    const price = prices[clothType];
    return ({
      id,
      name,
      quantity,
      hasPriceRange,
      price: hasPriceRange ? ((price.max + price.min) / 2) : price,
      priceRange: hasPriceRange && (price.max - price.min),
      liningUnitPrice: liningPrices?.[clothType] || 0,
    });
  })
);

const getUpcyclingItems = (upcyclingPricingInverse, upcyclingItems, clothType) => {
  const newUpcyclingItems = getUpcyclingItemsFromPricingInverse(upcyclingPricingInverse, upcyclingItems);
  return getFormattedUpcyclingItems(newUpcyclingItems, clothType);
};

export function getAlterationsForLeatherGoods(
  treeInverse,
  leatherGoodsPricingInverse,
  {
    typeOfWork, clothSlug, problems, sizeBag,
  },
) {
  if (['prevent', 'cure', 'recolor'].includes(typeOfWork)) {
    return getAlterationsFromProblems(treeInverse, leatherGoodsPricingInverse, { clothSlug, problems, sizeBag });
  }
  return [];
}

export function getAlterationsForCloth(
  treeInverse,
  pricingInverse,
  {
    typeOfWork, clothSlug, problems,
    embroideries, isDrawingEasy, isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
    creationSize, creationFirstOption, creationSecondOption, creationHeight,
    creationNumberOfPieces, otherItems, process, embroideriesPackage, embroideryPackageDrawingSize,
    embroideryPackageFontSize, suitPiece, knitTearOption, pantHemStyle, fabric,
  },
) {
  if (['alteration', 'repair'].includes(typeOfWork)) {
    return getAlterationsFromProblems(treeInverse, pricingInverse, {
      clothSlug, problems, knitTearOption, pantHemStyle, fabric, process,
    });
  }
  if (typeOfWork === 'embroidery' && process === 'PACKAGE') {
    return getAlterationsFromEmbroideriesPackage(pricingInverse, {
      clothSlug, embroideriesPackage, embroideryPackageFontSize, embroideryPackageDrawingSize,
    });
  }
  if (typeOfWork === 'embroidery') {
    return getAlterationsFromEmbroideries(pricingInverse, {
      clothSlug,
      embroideries,
      isDrawingEasy,
      isDrawingFull,
      activeEmbroideryDrawingSize,
      activeEmbroideryFontSize,
      suitPiece,
    });
  }
  if (typeOfWork === 'creation') {
    return getAlterationsFromCreations(pricingInverse, {
      creationSize,
      creationHeight,
      creationFirstOption,
      creationSecondOption,
      creationNumberOfPieces,
      otherItems,
    });
  }
  return [];
}

export const getCreationType = (creationShape, creationSize) => (
  Object.keys(creationShape).length ? `${creationShape.slug}_${creationSize.slug}` : creationSize.slug
);

export const getClothType = (piece, fabric, tree, suitPiece) => {
  if (suitPiece) {
    return tree.find(({ slug }) => slug === piece).pieces[suitPiece]?.hasNoTypes ? 'DEFAULT' : 'COMPLEX';
  }
  if (tree.find(({ slug }) => slug === piece)?.hasNoTypes) return 'DEFAULT';
  if (!fabric) return 'SIMPLE';
  if (!['other', 'jeans'].includes(fabric)) return 'COMPLEX';
  return 'SIMPLE';
};

const getLeatherGoodsOrShoesType = (typeOfWork, fabric, problems) => {
  if (['prevent', 'cure'].includes(typeOfWork) && (problems?.dirty)) {
    if (fabric === 'suede') return 'COMPLEX';
    return 'SIMPLE';
  }
  return 'SIMPLE';
};

export const getClothName = (tree, clothSlug, suitPiece = undefined) => {
  let clothName = '';
  const treeCloth = ['crea_chai', 'crea_stoo'].includes(clothSlug)
    ? tree.answers.find((cloth) => cloth.clothSlug === clothSlug)
    : tree.find((cloth) => cloth.slug === clothSlug);
  if (suitPiece) {
    clothName = treeCloth.pieces[suitPiece].name;
  } else {
    clothName = treeCloth?.name || '';
  }
  return clothName;
};

const maisPiece = ['sheet', 'plaid'];

function getSlug(selectedPiece, suitPiece) {
  if (suitPiece) return suitPiece;
  if (selectedPiece === 'mail') return 'ling';
  if (maisPiece.includes(selectedPiece)) return 'mais';
  if (selectedPiece === 'napkins') return 'tableClothes';
  if (shoesTypes.includes(selectedPiece)) return 'shoes';
  return selectedPiece;
}

export const formatCloth = (
  tree,
  treeInverse,
  pricingInverse,
  upcyclingPricingInverse,
  {
    selectedCloth, selectedLining, isCurtainLined, selectedProblems,
    selectedFabric, selectedTypeOfWork, embroideries, isDrawingEasy,
    isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
    creationSlug, creationShape, creationSize, creationHeight,
    creationFirstOption, creationSecondOption, selectedCreationNumberOfPieces,
    otherItems, upcyclingItems, suitPiece, index, customerComment, hemCentimeters,
    selectedProcess, embroideriesPackage, embroideryPackageTop, embroideryPackageFont, embroideryPackageWhere,
    embroideryPackageFontSize, embroideryPackageColor, embroideryPackageMessage,
    embroideryPackageDrawing, embroideryPackageDrawingSize, knitTearOption, pantHemStyle, weddingDate,
  },
  brand,
) => ({
  index,
  slug: selectedTypeOfWork === 'creation' ? creationSlug : getSlug(selectedCloth, suitPiece),
  name: getClothName(tree, selectedTypeOfWork === 'creation' ? creationSlug : selectedCloth, suitPiece),
  suitPiece,
  liningsNumber: (selectedLining === 'haveLining' || isCurtainLined) ? 1 : 0,
  fabric: selectedCloth === 'mail' ? 'bath' : selectedFabric,
  typesOfWork: [selectedTypeOfWork],
  clothType: selectedTypeOfWork === 'creation' ? getCreationType(creationShape, creationSize) : getClothType(selectedCloth, selectedFabric, tree, suitPiece),
  brand: !!brand,
  hasTalkRDV: (selectedProblems && Object.keys(selectedProblems).includes('talkRDV')) || (embroideries && embroideries[3]?.isActive),
  upcyclingItems: (selectedTypeOfWork !== 'transformation' || upcyclingItems.length < 1)
    ? [] : getUpcyclingItems(upcyclingPricingInverse, upcyclingItems, getClothType(selectedCloth, selectedFabric, tree, suitPiece)),
  customerComment,
  hemCentimeters,
  pantHemStyle,
  process: selectedProcess,
  weddingDate,
  embroideriesPackageInfos: embroideriesPackage?.some((embroideryPackage) => embroideryPackage.isActive)
    ? {
      typeOfTop: embroideryPackageTop,
      font: embroideryPackageFont,
      where: embroideryPackageWhere,
      fontSize: embroideryPackageFontSize,
      color: embroideryPackageColor,
      message: embroideryPackageMessage,
      drawing: embroideryPackageDrawing,
      drawingSize: embroideryPackageDrawingSize,
    } : null,
  items: getAlterationsForCloth(
    treeInverse,
    pricingInverse,
    {
      typeOfWork: selectedTypeOfWork,
      clothSlug: selectedCloth,
      problems: selectedProblems,
      fabric: selectedFabric,
      embroideries,
      isDrawingEasy,
      isDrawingFull,
      activeEmbroideryDrawingSize,
      activeEmbroideryFontSize,
      creationSize,
      creationHeight,
      creationFirstOption,
      creationSecondOption,
      creationNumberOfPieces: selectedCreationNumberOfPieces,
      otherItems,
      process: selectedProcess,
      embroideriesPackage,
      embroideryPackageFontSize,
      embroideryPackageDrawingSize,
      suitPiece,
      knitTearOption,
      pantHemStyle,
    },
  )
    .map((alteration) => {
      const type = selectedTypeOfWork === 'creation'
        ? getCreationType(creationShape, creationSize)
        : getClothType(selectedCloth, selectedFabric, tree, suitPiece);
      if (!alteration?.id) {
        console.error('[formatCloth] alteration not found', { // log error for sentry
          typeOfWork: selectedTypeOfWork,
          clothSlug: selectedCloth,
          problems: selectedProblems,
          embroideries,
          isDrawingEasy,
          isDrawingFull,
          activeEmbroideryDrawingSize,
          activeEmbroideryFontSize,
          creationSize,
          creationHeight,
          creationFirstOption,
          creationSecondOption,
          creationNumberOfPieces: selectedCreationNumberOfPieces,
          otherItems,
          process: selectedProcess,
          embroideriesPackage,
          embroideryPackageFontSize,
          embroideryPackageDrawingSize,
          suitPiece,
          knitTearOption,
        });
      }
      return ({
        id: alteration.id,
        name: alteration.name,
        quantity: alteration.quantity || 1,
        price: alteration.prices[type],
        liningUnitPrice: alteration.liningPrices?.[type],
      });
    }),
});

function getDecoPieceName(piece, numberOfPieces) {
  if (piece === 'ride') return (numberOfPieces > 1) ? 'Rideaux' : 'Rideau';
  if (piece === 'cous') return (numberOfPieces > 1) ? 'Housses de coussin' : 'Housse de coussin';
  if (piece === 'plaid') return 'Plaid';
  if (piece === 'sheet') return 'Drap/Housse de couette';
  if (piece === 'sofa') return (numberOfPieces > 1) ? 'Housses de canapé' : 'Housse de canapé';
  if (piece === 'chair') return (numberOfPieces > 1) ? 'Housses de chaise' : 'Housse de chaise';
  if (piece === 'tableClothes') return (numberOfPieces > 1) ? 'Nappes' : 'Nappe';
  if (piece === 'napkins') return (numberOfPieces > 1) ? 'Serviettes' : 'Serviette';
  return '';
}

function getLiningLabel(isCurtainLined, numberOfPieces) {
  if (!isCurtainLined) return '';
  if (isMultilingual) return ''; // TODO: quick hack => do something cleaner for english version
  return (numberOfPieces > 1) ? ' doublés' : ' doublé';
}

export function getDecoName(piece, numberOfPieces, isCurtainLined) {
  const pieceName = getDecoPieceName(piece, numberOfPieces);
  const liningLabel = getLiningLabel(isCurtainLined, numberOfPieces);
  const quantityLabel = (numberOfPieces > 1 && !['sheet', 'plaid'].includes(piece)) ? ` (x${numberOfPieces})` : '';
  return `${pieceName}${liningLabel}${quantityLabel}`;
}

function getAlterationsForCurtain(t, pricingInverse, {
  curtainAlterations = [], activeCurtainAdjustment,
}) {
  return curtainAlterations
    .filter((alteration) => alteration.isActive)
    .filter((alteration) => alteration.id || alteration?.adjustments?.(t))
    .map((alteration) => alteration.id
      ? pricingInverse[alteration.id]
      : pricingInverse[alteration?.adjustments?.(t)[activeCurtainAdjustment].id]);
}

function getAlterationsForCurtainPackage(t, pricingInverse, {
  curtainAlterationsPackage = [], activeCurtainAdjustment,
}) {
  const alterations = curtainAlterationsPackage.reduce((acc, alteration) => {
    if (!alteration.isActive) {
      return acc;
    }
    const curtainTear = curtainAlterationsPackage[1]?.isActive;
    if (curtainTear || alteration.id || alteration?.adjustments?.(t)) {
      if (alteration.id) {
        const alterationWithQuantity = pricingInverse[alteration.id];
        alterationWithQuantity.quantity = alteration.question?.answer || 1;
        acc.push(alterationWithQuantity);
      } else if (alteration.adjustments && alteration?.adjustments?.(t)) {
        const activeAdjustment = alteration?.adjustments?.(t)[activeCurtainAdjustment];
        if (activeAdjustment && activeAdjustment.id) {
          acc.push(pricingInverse[activeAdjustment.id]);
        }
      }
    }

    return acc;
  }, []);

  return alterations;
}

function getRepairsForCurtain(pricingInverse, {
  curtainRepairs = [],
}) {
  const repairs = [];
  curtainRepairs
    .filter(({ isActive }) => isActive)
    .forEach(({ id, question: { answer } }) => {
      repairs.push(pricingInverse[id]);
      repairs[repairs.length - 1].quantity = answer;
    });
  return repairs;
}

function getAlterationsForCoversDeco(pricingInverse, { coversDecoAlterations }) {
  const alterations = [];
  coversDecoAlterations.forEach((coversDecoAlteration) => {
    if (coversDecoAlteration.isActive) {
      alterations.push(pricingInverse[coversDecoAlteration.id]);
    }
  });
  return alterations;
}

function getAlterationsForLinens(pricingInverse, {
  linensProblems, linensAlterations, linensSizes, linensWhere,
}) {
  if (!linensProblems[0].isActive && !linensProblems[1].isActive) return [];
  const alterations = [];
  if (linensProblems[0].isActive) {
    linensAlterations
      .filter(({ isActive }) => isActive)
      .forEach(({ id, question: { answer } }) => {
        alterations.push(pricingInverse[id]);
        alterations[alterations.length - 1].quantity = answer;
      });
  }
  if (linensProblems[1].isActive) {
    const size = linensSizes.filter(({ isActive }) => isActive)[0];
    const where = linensWhere.filter(({ isActive }) => isActive)[0];

    alterations.push(pricingInverse[size[`${where.slug}Id`]]);
  }
  return alterations;
}

function getAlterationsForDeco(t, pricingInverse, {
  selectedPiece, curtainAlterations, activeCurtainAdjustment, cushionAlterations, tableClothesAlterations,
  sofaAlterations, chairAlterations, embroideries, isDrawingEasy, isDrawingFull,
  activeEmbroideryDrawingSize, activeEmbroideryFontSize, linensProblems, linensAlterations, linensSizes, linensWhere,
  typeOfWork, creationSize, creationHeight, creationFirstOption, creationSecondOption, creationNumberOfPieces,
  curtainRepairs, curtainAlterationsPackage, embroideriesPackage, process, embroideryPackageFontSize,
  embroideryPackageDrawingSize,
}) {
  const piece = getSlug(selectedPiece);
  let coversDecoAlterations;
  switch (piece) {
    case 'tableClothes':
    case 'napkins':
      coversDecoAlterations = tableClothesAlterations;
      break;
    case 'sofa':
      coversDecoAlterations = sofaAlterations;
      break;
    case 'chair':
      coversDecoAlterations = chairAlterations;
      break;
    default:
      coversDecoAlterations = cushionAlterations;
  }
  if (typeOfWork === 'alteration') {
    if (piece === 'ride' && process !== 'PACKAGE') {
      return getAlterationsForCurtain(t, pricingInverse, { curtainAlterations, activeCurtainAdjustment });
    }
    if (piece === 'ride' && process === 'PACKAGE') {
      return getAlterationsForCurtainPackage(t, pricingInverse, { curtainAlterationsPackage, activeCurtainAdjustment });
    }
    if (piece === 'mais') {
      return getAlterationsForLinens(
        pricingInverse,
        {
          linensProblems,
          linensAlterations,
          linensSizes,
          linensWhere,
        },
      );
    }
  }
  if (typeOfWork === 'repair') {
    if (piece === 'ride') {
      return getRepairsForCurtain(pricingInverse, { curtainRepairs });
    }
    if (['cous', 'sofa', 'chair', 'napkins', 'tableClothes'].includes(piece)) {
      return getAlterationsForCoversDeco(pricingInverse, { coversDecoAlterations });
    }
    if (piece === 'mais') {
      return getAlterationsForLinens(
        pricingInverse,
        {
          linensProblems,
          linensAlterations,
          linensSizes,
          linensWhere,
        },
      );
    }
  }
  if (typeOfWork === 'embroidery' && process === 'PACKAGE') {
    return getAlterationsFromEmbroideriesPackage(pricingInverse, {
      clothSlug: piece, embroideriesPackage, embroideryPackageFontSize, embroideryPackageDrawingSize,
    });
  }
  if (typeOfWork === 'embroidery') {
    return getAlterationsFromEmbroideries(pricingInverse, {
      clothSlug: piece,
      embroideries,
      isDrawingEasy,
      isDrawingFull,
      activeEmbroideryDrawingSize,
      activeEmbroideryFontSize,
    });
  }
  if (typeOfWork === 'creation') {
    return getAlterationsFromCreations(pricingInverse, {
      creationSize,
      creationHeight,
      creationFirstOption,
      creationSecondOption,
      creationNumberOfPieces,
    });
  }
  return [];
}

export const formatPiece = (
  t,
  tree,
  pricingInverse,
  {
    selectedPiece, isCurtainLined, curtainAlterations, numberOfPieces,
    activeCurtainAdjustment, isCushionSmall, cushionHasZip, cushionAlterations, embroideries,
    isDrawingEasy, isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
    linensProblems, linensAlterations, linensSizes, linensWhere, selectedTypeOfWork,
    creationSlug, creationShape, creationSize, creationHeight, creationFirstOption,
    creationSecondOption, selectedCreationNumberOfPieces, otherItems, curtainRepairs,
    index, customerComment, curtainAlterationsPackage, leftHemCentimeters, rightHemCentimeters,
    selectedProcess, tableClothesAlterations, sofaAlterations, chairAlterations, embroideriesPackage,
    embroideryPackageTop, embroideryPackageFont, embroideryPackageWhere,
    embroideryPackageFontSize, embroideryPackageColor, embroideryPackageMessage,
    embroideryPackageDrawing, embroideryPackageDrawingSize,
  },
  brand,
) => ({
  index,
  slug: selectedTypeOfWork === 'creation' ? creationSlug : getSlug(selectedPiece),
  name: selectedTypeOfWork === 'creation'
    ? getClothName(tree, creationSlug)
    : getDecoName(selectedPiece, numberOfPieces, isCurtainLined, curtainAlterations),
  liningsNumber: isCurtainLined ? 1 : 0,
  typesOfWork: [selectedTypeOfWork],
  clothType: selectedTypeOfWork === 'creation' ? getCreationType(creationShape, creationSize) : 'DEFAULT',
  brand: !!brand,
  hasTalkRDV: embroideries && embroideries[3]?.isActive,
  customerComment,
  leftHemCentimeters,
  rightHemCentimeters,
  process: selectedProcess,
  embroideriesPackageInfos: embroideriesPackage?.some((embroideryPackage) => embroideryPackage.isActive)
    ? {
      typeOfTop: embroideryPackageTop,
      font: embroideryPackageFont,
      where: embroideryPackageWhere,
      fontSize: embroideryPackageFontSize,
      color: embroideryPackageColor,
      message: embroideryPackageMessage,
      drawing: embroideryPackageDrawing,
      drawingSize: embroideryPackageDrawingSize,
    } : null,
  items: getAlterationsForDeco(
    t,
    pricingInverse,
    {
      selectedPiece,
      isCurtainLined,
      curtainAlterations,
      numberOfPieces,
      activeCurtainAdjustment,
      isCushionSmall,
      cushionHasZip,
      cushionAlterations,
      tableClothesAlterations,
      sofaAlterations,
      chairAlterations,
      embroideries,
      isDrawingEasy,
      isDrawingFull,
      activeEmbroideryDrawingSize,
      activeEmbroideryFontSize,
      linensProblems,
      linensAlterations,
      linensSizes,
      linensWhere,
      typeOfWork: selectedTypeOfWork,
      creationSize,
      creationHeight,
      creationFirstOption,
      creationSecondOption,
      creationNumberOfPieces: selectedCreationNumberOfPieces,
      otherItems,
      curtainRepairs,
      curtainAlterationsPackage,
      embroideriesPackage,
      process: selectedProcess,
      embroideryPackageFontSize,
      embroideryPackageDrawingSize,
    },
  )
    .map((alteration) => {
      const type = selectedTypeOfWork === 'creation' ? getCreationType(creationShape, creationSize) : 'DEFAULT';
      const curtainRepairInAlterations = curtainAlterationsPackage[1]?.isActive;
      const quantityForRepairAndLinen = (['repair', 'creation'].includes(selectedTypeOfWork) || ['sheet', 'plaid'].includes(selectedPiece) || curtainRepairInAlterations)
      && alteration.quantity;
      if (!alteration?.id) {
        console.error('[formatPiece] alteration is missing', { // log error for sentry
          selectedPiece,
          isCurtainLined,
          curtainAlterations,
          numberOfPieces,
          activeCurtainAdjustment,
          isCushionSmall,
          cushionHasZip,
          cushionAlterations,
          tableClothesAlterations,
          sofaAlterations,
          chairAlterations,
          embroideries,
          isDrawingEasy,
          isDrawingFull,
          activeEmbroideryDrawingSize,
          activeEmbroideryFontSize,
          linensProblems,
          linensAlterations,
          linensSizes,
          linensWhere,
          typeOfWork: selectedTypeOfWork,
          creationSize,
          creationHeight,
          creationFirstOption,
          creationSecondOption,
          creationNumberOfPieces: selectedCreationNumberOfPieces,
          otherItems,
          curtainRepairs,
          curtainAlterationsPackage,
          embroideriesPackage,
          process: selectedProcess,
          embroideryPackageFontSize,
          embroideryPackageDrawingSize,
        });
      }
      return ({
        id: alteration.id,
        name: alteration.name,
        quantity: quantityForRepairAndLinen || numberOfPieces,
        price: alteration.prices[type],
        liningUnitPrice: alteration.liningPrices?.[type],
      });
    }),
});

export const getSofaPartName = (tree, slug, isForOrder) => {
  const treeSofaPart = Object.entries(tree.parts).find(([key]) => key === slug);
  return treeSofaPart[1][isForOrder ? 'nameForOrder' : 'name'];
};

const getSubType = (pricingSubType, subTypes) => {
  const subType = subTypes[pricingSubType.slug];
  if (subType === 'UNDEFINED' || !subType) {
    return pricingSubType.items[0].slug;
  }
  return subType;
};

export const getSofaPartType = (pricing, slug, subTypes) => {
  const sofaPartSubTypes = pricing.find((item) => item.slug === slug).subTypes;
  let type = '';
  sofaPartSubTypes.forEach((subType, index) => {
    type = `${type}${getSubType(subType, subTypes)}${index === sofaPartSubTypes.length - 1 ? '' : '_'}`;
  });
  return type;
};

const getAlterationsForSofaParts = (pricing, pricingInverse, { sofaPart, type, sofaPartItems }) => {
  const alterations = [];
  const pricingTypes = pricing.find((item) => item.slug === sofaPart).types;
  const pricingType = pricingTypes.find((item) => item.slug === type);
  alterations.push(pricingInverse[pricingType.id]);
  Object.values(sofaPartItems).forEach((item) => pricingInverse[item] && alterations.push(pricingInverse[item]));
  return alterations;
};

export const formatSofaPart = (
  tree,
  pricing,
  pricingInverse,
  {
    selectedTypeOfWork, sofaPart,
    sofaPartSubTypes, sofaPartItems, sofaPartNumber,
    index,
  },
  brand,
  isForOrder,
) => {
  const type = getSofaPartType(pricing, sofaPart, sofaPartSubTypes);
  return ({
    index,
    slug: sofaPart,
    name: getSofaPartName(tree, sofaPart, isForOrder),
    liningsNumber: 0,
    typesOfWork: [selectedTypeOfWork],
    clothType: type,
    sofaPartSubTypes,
    brand: !!brand,
    hasTalkRDV: false,
    sofaPart,
    items: getAlterationsForSofaParts(
      pricing,
      pricingInverse,
      {
        sofaPart,
        type,
        sofaPartItems,
      },
    )
      .map((alteration, indexAlteration) => ({
        id: alteration.id,
        name: alteration.name,
        quantity: sofaPartNumber,
        price: alteration.prices[type],
        liningUnitPrice: alteration.liningPrices?.[type] || 0,
        hide: indexAlteration === 0,
      })),
  });
};

export const formatLeatherGoods = (
  tree,
  treeInverse,
  leatherGoodsPricingInverse,
  {
    selectedProblems,
    selectedFabric, selectedTypeOfWork, index, customerComment,
    selectedAccessory, selectedProcess, selectedSizeBag,
  },
  brand,
) => (
  {
    index,
    slug: getSlug(selectedAccessory),
    shoesType: shoesTypes.includes(selectedAccessory) ? selectedAccessory : undefined,
    name: getClothName(tree, selectedAccessory),
    fabric: selectedFabric,
    typesOfWork: [selectedTypeOfWork],
    clothType: getLeatherGoodsOrShoesType(selectedTypeOfWork, selectedFabric, selectedProblems),
    brand: !!brand,
    customerComment,
    sizeBag: selectedSizeBag,
    process: selectedProcess,
    items: getAlterationsForLeatherGoods(
      treeInverse,
      leatherGoodsPricingInverse,
      {
        typeOfWork: selectedTypeOfWork,
        clothSlug: selectedAccessory,
        problems: selectedProblems,
        sizeBag: selectedSizeBag,
      },
    )
      .map((alteration) => {
        const type = getLeatherGoodsOrShoesType(selectedTypeOfWork, selectedFabric, selectedProblems);
        return ({
          id: alteration.id,
          name: alteration.name,
          quantity: alteration.quantity || 1,
          price: alteration.prices[type],
        });
      }),
  });

export const formatShoes = (
  tree,
  treeInverse,
  leatherGoodsPricingInverse,
  {
    selectedProblems,
    selectedFabric, selectedTypeOfWork, index, customerComment,
    selectedShoes, selectedProcess,
  },
  brand,
) => (
  {
    index,
    slug: getSlug(selectedShoes),
    shoesType: selectedShoes,
    name: getClothName(tree, selectedShoes),
    fabric: selectedFabric,
    typesOfWork: [selectedTypeOfWork],
    clothType: getLeatherGoodsOrShoesType(selectedTypeOfWork, selectedFabric, selectedProblems),
    brand: !!brand,
    customerComment,
    process: selectedProcess,
    items: getAlterationsForLeatherGoods(
      treeInverse,
      leatherGoodsPricingInverse,
      {
        typeOfWork: selectedTypeOfWork,
        clothSlug: selectedShoes,
        problems: selectedProblems,
      },
    )
      .map((alteration) => {
        const type = getLeatherGoodsOrShoesType(selectedTypeOfWork, selectedFabric, selectedProblems);
        return ({
          id: alteration.id,
          name: alteration.name,
          quantity: alteration.quantity || 1,
          price: alteration.prices[type],
        });
      }),
  });

export function formatClothOrPiece(
  t,
  tree,
  treeInverse,
  pricing,
  pricingInverse,
  upcyclingPricingInverse,
  leatherGoodsPricingInverse,
  clothOrPiece,
  brand,
  isForOrder = false,
) {
  if (clothOrPiece.selectedTypeOfWork === 'creation' && clothOrPiece.sofaPart) {
    return formatSofaPart(tree, pricing, pricingInverse, clothOrPiece, brand, isForOrder);
  }
  if (clothOrPiece.selectedCategory === 'deco') return formatPiece(t, tree, pricingInverse, clothOrPiece, brand);
  if (clothOrPiece.selectedCategory === 'accessories' && clothOrPiece.selectedCloth !== 'acce') {
    return formatLeatherGoods(tree, treeInverse, leatherGoodsPricingInverse, clothOrPiece, brand);
  }
  if (clothOrPiece.selectedCategory === 'shoes') return formatShoes(tree, treeInverse, leatherGoodsPricingInverse, clothOrPiece, brand);

  return formatCloth(tree, treeInverse, pricingInverse, upcyclingPricingInverse, clothOrPiece, brand);
}

export function formatAllDaySlots(selectedDate) {
  const slots = [];
  const { hours, minutes } = selectedDate.slotBlocks[0].slots[0];
  const begin = moment(selectedDate.date).set({
    hours, minutes, seconds: 0, milliseconds: 0,
  });

  const lastSlotBlock = selectedDate.slotBlocks[selectedDate.slotBlocks.length - 1];
  const slotsLength = lastSlotBlock.slots.length;
  const { hoursEnd, minutesEnd } = lastSlotBlock.slots[slotsLength - 1];
  const end = moment(selectedDate.date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
  });

  const current = moment(begin);

  while (!current.isSameOrAfter(end, 'minutes')) {
    slots.push({
      begin: current.toDate(),
      end: moment(current).add(30, 'minutes').toDate(),
    });
    current.add(30, 'minutes');
  }

  return slots;
}

export function formatBlockSlots(date, slotBlock) {
  const slots = [];
  const { hours, minutes } = slotBlock.slots[0];
  const begin = moment(date).set({
    hours, minutes, seconds: 0, milliseconds: 0,
  });

  const slotsLength = slotBlock.slots.length - 1;
  const { hoursEnd, minutesEnd } = slotBlock.slots[slotsLength];
  const end = moment(date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
  });

  const current = moment(begin);

  while (!current.isSameOrAfter(end, 'minutes')) {
    slots.push({
      begin: current.toDate(),
      end: moment(current).add(30, 'minutes').toDate(),
    });
    current.add(30, 'minutes');
  }

  return slots;
}

export function formatSlot(date, slot) {
  const { hours, minutes } = slot;
  const begin = moment(date).set({
    hours, minutes, seconds: 0, milliseconds: 0,
  }).toDate();

  const { hoursEnd, minutesEnd } = slot;
  const end = moment(date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
  }).toDate();
  return { begin, end };
}

export function formatSlots(selectedDates, selectedPerfectSlot = {}) {
  const slots = [];
  Object.values(selectedDates).forEach((selectedDate) => {
    if (selectedDate.allDay.isActive) {
      slots.push(...formatAllDaySlots(selectedDate));
    } else {
      selectedDate.slotBlocks.forEach((slotBlock) => {
        if (slotBlock.isActive) {
          slots.push(...formatBlockSlots(selectedDate.date, slotBlock));
        } else {
          slotBlock.slots.filter((slot) => slot.isActive).forEach((slot) => {
            slots.push(formatSlot(selectedDate.date, slot));
          });
        }
      });
    }
  });
  if (Object.keys(selectedPerfectSlot).length > 0) {
    const { begin, end } = selectedPerfectSlot;
    slots.push({ begin, end });
  }
  return slots;
}

export function hasEmbroidery(clothes) {
  return clothes.reduce((hasEmbroideryIt, cloth) => {
    const { embroideries } = cloth;
    if (
      embroideries?.length === 4
      && (embroideries[0].isActive || embroideries[1].isActive
        || embroideries[2].isActive || embroideries[3].isActive)
    ) return true;
    return hasEmbroideryIt;
  }, false);
}

export const getCompleteFabrics = (clothes = []) => {
  let completeFabrics = [];
  if (clothes.length !== 0) {
    completeFabrics = clothes.reduce((fabricsAcc, { selectedTypeOfWork, selectedFabric }) => {
      if (selectedFabric?.startsWith('textile_')) {
        const fabric = selectedFabric.split('_')[1];
        fabricsAcc.push(fabric);
      } else if (
        !['', 'suede', 'leatherAcce'].includes(selectedFabric)
        && !(selectedTypeOfWork === 'embroidery')
      ) {
        fabricsAcc.push(selectedFabric);
      }
      return fabricsAcc;
    }, []);

    if (hasEmbroidery(clothes)) completeFabrics.push('embroidery');
    if (clothes.find((cloth) => cloth.selectedCloth === 'mari')) completeFabrics = [...completeFabrics, 'silk', 'lace'];
    if (clothes.find((cloth) => cloth.selectedPiece === 'ride')) completeFabrics.push('curtain');
    if (clothes.find((cloth) => cloth.selectedCloth === 'ling')) completeFabrics.push('lingerie');
    if (clothes.find((cloth) => cloth.selectedCloth === 'mail')) completeFabrics.push('bath');
    if (clothes.find((cloth) => ['pull', 'little_acce'].includes(cloth.selectedCloth))) completeFabrics.push('stitch');
    if (clothes.find((cloth) => cloth.selectedCloth === 'mant' && cloth.selectedTypeOfWork !== 'embroidery')) completeFabrics.push('suit');
    if (clothes.find((cloth) => cloth.selectedTypeOfWork === 'creation' && cloth.selectedCategory !== 'deco')) completeFabrics.push('creation');
    if (clothes.find((cloth) => cloth.selectedTypeOfWork === 'creation' && cloth.selectedCategory === 'deco')) completeFabrics.push('house');
  }
  return completeFabrics.filter((fabric) => fabric !== 'other');
};

const getSuitFabric = (typeOfWork, suitPiece, suitItems) => {
  switch (typeOfWork) {
    case 'alteration':
      return suitItems.filter(({ piece }) => piece === suitPiece).every(({ isNotSuit }) => isNotSuit) ? '' : 'suit';
    case 'repair':
      return suitItems.filter(({ piece }) => piece === suitPiece).every(({ isNotSuit }) => isNotSuit) ? '' : 'suit';
    case 'embroidery':
      return 'embroidery';
    case 'transformation':
      return 'suit';
    default:
      return '';
  }
};

// dead code ??
export const getClothesWithSuitPiecesAndSofaPartsSplitted = (clothes) => {
  const clothesWithSuitPiecesAndSofaPartsSplitted = [];
  clothes.forEach((cloth, index) => {
    if (cloth.selectedCloth === 'cost' && ['alteration', 'repair', 'embroidery', 'transformation'].includes(cloth.selectedTypeOfWork)) {
      cloth.suitPieces.forEach((suitPiece) => {
        const problems = {};
        cloth.suitProblems.filter((suitProblem) => suitProblem.includes(`${suitPiece}_`)).forEach((suitProblem) => {
          const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
          problems[suitProblemSlug] = [];
          cloth.suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblemSlug).forEach(({ isMultiple, slug, quantity }) => {
            const suitItemSlug = `${suitPiece}_${slug}`;
            problems[suitProblemSlug].push(suitItemSlug);
            if (isMultiple) problems[`${suitItemSlug}_multiple`] = [quantity];
          });
        });
        clothesWithSuitPiecesAndSofaPartsSplitted.push({
          ...cloth,
          index,
          selectedProblems: problems,
          selectedLining: cloth.suitPieceLinings[suitPiece] || '',
          suitPiece,
          selectedFabric: getSuitFabric(cloth.selectedTypeOfWork, suitPiece, cloth.suitItems),
          embroideries: cloth.suitPiecesEmbroideries[suitPiece]?.embroideries ?? [],
          activeEmbroideryFontSize: cloth.suitPiecesEmbroideries[suitPiece]?.activeEmbroideryFontSize ?? -1,
          activeEmbroideryDrawingSize: cloth.suitPiecesEmbroideries[suitPiece]?.activeEmbroideryDrawingSize ?? -1,
          isDrawingFull: cloth.suitPiecesEmbroideries[suitPiece]?.isDrawingFull ?? undefined,
          isDrawingEasy: cloth.suitPiecesEmbroideries[suitPiece]?.isDrawingEasy ?? undefined,
          upcyclingCategories: cloth.suitPiecesUpcycling[suitPiece]?.upcyclingCategories ?? [],
          upcyclingItems: cloth.suitPiecesUpcycling[suitPiece]?.upcyclingItems ?? [],
        });
      });
    } else if (cloth.selectedTypeOfWork === 'creation' && cloth.creationSlug === 'crea_sofa') {
      cloth.sofaParts.forEach((sofaPart) => {
        clothesWithSuitPiecesAndSofaPartsSplitted.push({
          ...cloth,
          index,
          sofaPart,
          sofaPartSubTypes: cloth.sofaPartsSubTypes[sofaPart],
          sofaPartItems: cloth.sofaPartsItems[sofaPart],
          sofaPartNumber: cloth.sofaPartsNumber[sofaPart],
          selectedFabric: 'house',
        });
      });
    } else {
      clothesWithSuitPiecesAndSofaPartsSplitted.push({ ...cloth, index });
    }
  });
  return clothesWithSuitPiecesAndSofaPartsSplitted;
};
